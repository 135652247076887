<i18n>
{
  "en": {
    "sites_movies_0": "Latest Movies from Our Sister Site '",
    "sites_movies_1": "'",
    "site_1pondo": "1pondo",
    "site_10m": "10musume",
    "site_paco": "Pacopacomama",
    "site_mura": "Muramura",
    "site_carib": "Caribbeancom",
    "site_caribpr": "Caribbeancom Premium"
  },
  "ja": {
    "sites_movies_0": "姉妹サイト「",
    "sites_movies_1": "」の最新情報",
    "site_1pondo": "一本道",
    "site_10m": "天然むすめ",
    "site_paco": "パコパコママ",
    "site_mura": "ムラムラってくる素人",
    "site_carib": "カリビアンコム",
    "site_caribpr": "カリビアンコムプレミアム"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <!-- set section header with site name -->
    <h3 v-if="siteName === '1pon'">{{ $t('sites_movies_0') }}{{ $t('site_1pondo') }}{{ $t('sites_movies_1') }}</h3>
    <h3 v-else-if="siteName === '10m'">{{ $t('sites_movies_0') }}{{ $t('site_10m') }}{{ $t('sites_movies_1') }}</h3>
    <h3 v-else-if="siteName === 'paco'">{{ $t('sites_movies_0') }}{{ $t('site_paco') }}{{ $t('sites_movies_1') }}</h3>
    <h3 v-else-if="siteName === 'mura'">{{ $t('sites_movies_0') }}{{ $t('site_mura') }}{{ $t('sites_movies_1') }}</h3>
    <h3 v-else-if="siteName === 'carib'">{{ $t('sites_movies_0') }}{{ $t('site_carib') }}{{ $t('sites_movies_1') }}</h3>
    <h3 v-else-if="siteName === 'caribpr'">{{ $t('sites_movies_0') }}{{ $t('site_caribpr') }}{{ $t('sites_movies_1') }}</h3>
    <!--
    carousel for landscape images: 1pondo, carib
    :perPageCustom: configure the number of visible slides with a particular browser width,
    formatted as [x, y] where x=browser width, and y=number of slides displayed
    -->
    <carousel v-if="siteName === '1pon' || siteName === 'carib'"
      :perPageCustom="[[300, 2], [960, 3]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :speed="1000"
      :class="`carousel-${siteName}`">
      <slide v-for="(movie, index) in movieList" :key="movie.MovieID">
        <a
          :href="movie.thumbnailHref"
          :data-site="siteNames[siteName]"
          :data-movie-id="movie.MovieID"
          :data-movie-title="movie.Title"
          :data-actress-name="movie.Actor"
          target="_blank"
          rel="noopener">
          <img :src="`${movie.thumbnailSrc}`" @error="useFallbackImage(index)" alt="" v-sfw>
          <div class="data-overlay">
            <div class="data-title">{{ movie.Title }}</div>
            <div class="data-actress">{{ movie.Actor }}</div>
          </div>
        </a>
      </slide>
    </carousel>
    <!--
    carousel for portrait/squared images: paco, 10m, mura
    :perPageCustom: configure the number of visible slides with a particular browser width,
    formatted as [x, y] where x=browser width, and y=number of slides displayed
    -->
    <carousel v-if="siteName === 'paco' || siteName === '10m' || siteName === 'mura'"
      :perPageCustom="[[300, 3], [640, 4], [800, 5], [960, 6], [1200, 7]]"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :speed="1000"
      :class="`carousel-${siteName}`">
      <slide v-for="(movie, index) in movieList" :key="movie.MovieID">
        <a
          :href="movie.thumbnailHref"
          :data-site="siteNames[siteName]"
          :data-movie-id="movie.MovieID"
          :data-movie-title="movie.Title"
          :data-actress-name="movie.Actor"
          target="_blank"
          rel="noopener">
          <img :src="`${movie.thumbnailSrc}`" @error="useFallbackImage(index)" alt="" v-sfw>
          <div class="data-overlay">
            <div class="data-title">{{ movie.Title }}</div>
            <div class="data-actress">{{ movie.Actor }}</div>
          </div>
        </a>
      </slide>
    </carousel>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import { Carousel, Slide } from 'vue-carousel';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    siteName: String,
    numMovies: Number,
    movieOffset: Number,
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      movieList: [],
    };
  },
  created() {
    this.getMovies(this.userTypeID);

    // site name maps for data attributes
    this.siteNames = {
      '1pon': '1pondo',
      '10m': '10musume',
      carib: 'Caribbeancom',
      paco: 'Pacopacomama',
      mura: 'Muramura',
    };
  },
  methods: {
    getMovies(userTypeID) {
      // fetch newest movies for requested site
      const bfAPI = new BifrostAPI();
      bfAPI.getMovieListBySite(this.movieOffset, parseInt(this.numMovies, 10), this.siteName).then((result) => {
        const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
        this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);

        // set image href link and src path for each site
        switch (this.siteName) {
          case '1pon':
            for (let i = 0; i < this.movieList.length; i += 1) {
              this.$set(this.movieList[i], 'thumbnailSrc', `https://www.1pondo.tv${this.movieList[i].thumbnailSrc}`);
              this.$set(this.movieList[i], 'thumbnailHref', `https://www.1pondo.tv/movies/${this.movieList[i].MovieID}/`);
            }
            break;
          case 'carib':
            for (let i = 0; i < this.movieList.length; i += 1) {
              this.$set(this.movieList[i], 'thumbnailSrc', `https://tarimages.caribbeancom.com/images/flash256x144/${this.movieList[i].RealMetaMovieID}.jpg`);
              this.$set(this.movieList[i], 'thumbnailHref', `https://www.caribbeancom.com/moviepages/${this.movieList[i].MovieID}/index.html`);
            }
            break;
          case 'caribpr':
            // TODO: to fill in when json data is available
            break;
          case '10m':
            for (let i = 0; i < this.movieList.length; i += 1) {
              this.$set(this.movieList[i], 'thumbnailSrc', `https://www.10musume.com${this.movieList[i].thumbnailSrc.replace('str_s.jpg', 'list1.jpg')}`);
              this.$set(this.movieList[i], 'thumbnailHref', `https://www.10musume.com/movies/${this.movieList[i].MovieID}/`);
            }
            break;
          case 'paco':
            for (let i = 0; i < this.movieList.length; i += 1) {
              this.$set(this.movieList[i], 'thumbnailSrc', `https://www.pacopacomama.com${this.movieList[i].thumbnailSrc.replace('str_s.jpg', 'l_thum.jpg')}`);
              this.$set(this.movieList[i], 'thumbnailHref', `https://www.pacopacomama.com/movies/${this.movieList[i].MovieID}/`);
            }
            break;
          case 'mura':
            for (let i = 0; i < this.movieList.length; i += 1) {
              this.$set(this.movieList[i], 'thumbnailSrc', this.movieList[i].MovieThumb);
              this.$set(this.movieList[i], 'thumbnailHref', `https://www.muramura.tv/moviepages/${this.movieList[i].MovieID}/index.html`);
            }
            break;
          default: break;
        }
      });
    },
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to "no image" image. since this is a "newest
      // movies" list, we can guarantee that if the thumbnail poster is missing, then this movie
      // isn't quite ready yet
      if (this.siteName === '1pon' || this.siteName === 'carib') {
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing.png');
      } else if (this.siteName === '10m' || this.siteName === 'mura') {
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing_portrait.png');
      } else if (this.siteName === 'paco') {
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing_square.png');
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
    numMovies(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
    movieOffset(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>

/* thumbnail overlay  */
.VueCarousel-inner {
  .VueCarousel-slide {
    text-align: center;

    a {
      display: inline-block;
      position: relative;
      margin: 0 0.5rem;

      img {
        width: 100%;;
      }

      .data-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        width: 100%;
        padding: 0.8rem 0.5rem;
        opacity: 0;
        transition: .5s ease;
        background-color: #fff;
        text-align: left;

        @media screen and (max-width: 640px) {
          top: 0;
          height: 100%;
        }

        .data-title, .data-actress {
          color: #333;
          font-size: 0.9rem;
          line-height: 1.4rem;
          max-height: 1.4rem;
          overflow: hidden;

          @media screen and (max-width: 640px) {
            width: 90%;
            position: absolute;
          }

          @media screen and (min-width: 640px) and (max-width: 1150px) {
            font-size: 0.8rem;
          }
        }

        .data-title {
          font-weight: 700;

          @media screen and (max-width: 640px) {
            top: 40%;
          }
        }

        .data-actress {
          @media screen and (max-width: 640px) {
            top: 55%;
          }
        }
      }

      &:hover .data-overlay {
        opacity: 0.6;
      }
    }
  }
}

/* carousel nav color overwrite */
.VueCarousel-navigation {
  .VueCarousel-navigation-button {
    color: #F50102;
  }
}

/* thumbnail max-width for each site */
// each site has its own thumbnail size but default fallback image is only one-sized
// set 'max-width' here to make sure the thumbnails in each carousel displayed in consistent ratio
.carousel-1pon img {
  max-width: 384px;
}

.carousel-carib img {
  max-width: 386px;
}

.carousel-caribpr img {
  // TODO: need thumbnail width here
}

.carousel-10m img {
  max-width: 157px;
}

.carousel-paco img {
  max-width: 150px;
}

.carousel-mura img {
  max-width: 125px;
}
</style>
